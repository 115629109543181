

























































































































import Vue from 'vue'
import { Job, BusinessArea } from '@/types'
import JobSearchPills from '@/components/JobSearchPills.vue'

export default Vue.extend({
  name: 'JobSearchList',
  components: {
    JobSearchPills,
  },
  data () {
    return {
      loading: false,
      searchText: '',
      searchTextPlaceholder: '',
    }
  },
  async created () {
    this.loading = true
    this.setSearch()
    this.loading = false
  },
  watch: {
    searchText () {
      this.$store.dispatch('setEnteredSearchText', this.searchText)
    },
  },
  computed: {
    businessAreas () : BusinessArea[] {
      return this.$store.state.businessAreas
    },
    selectedLocations () : string[] {
      return this.$store.state.selectedLocations
    },
    selectedBusinessAreas () : string[] {
      return this.$store.state.selectedBusinessAreas
    },
    selectedDepartments () : string[] {
      return this.$store.state.selectedDepartments
    },
    selectedShifts () : string[] {
      return this.$store.state.selectedShifts
    },
    filteredJobs () : Job[] {
      let allJobs = this.jobs
      if (this.selectedLocations.length > 0) {
        allJobs = allJobs.filter(j => this.selectedLocations.includes(j.officeCode))
      }
      if (this.selectedBusinessAreas.length > 0) {
        allJobs = allJobs.filter(j => this.selectedBusinessAreas.includes(j.businessAreaId))
      }
      if (this.selectedDepartments.length > 0) {
        allJobs = allJobs.filter(j => this.selectedDepartments.includes(j.departmentId))
      }
      if (this.selectedShifts.length > 0) {
        allJobs = allJobs.filter(j => this.selectedShifts.includes(j.shift))
      }
      if (this.searchText) {
        allJobs = allJobs.filter(j => this.matchesSearchText(j))
      }
      return this.lodash.orderBy(allJobs, ['priority', 'postingDate', 'title'], ['desc', 'desc', 'asc'])
    },
    jobs () : Job[] {
      return this.$store.state.jobs
    },
    showJobList () : boolean {
      return this.$store.state.showJobList
    },
    areJobsLoading () : boolean {
      return this.jobs.length === 0
    }
  },
  methods: {
    apply (jobId: string) : void {
      this.$router.push({name: 'JobDetails', params: {id: jobId}})
    },
    getLocation (code: string) : string {
      let loc = this.$store.getters.getLocation(code)
      return loc ? `${loc.city}, ${loc.state}` : ''
    },
    getDepartmentDescription (id: string) : string {
      let dep = this.$store.getters.getDepartment(id)
      return dep ? dep.description : ''
    },
    getBusinessAreaDescription (id: string) : string {
      let area = this.$store.getters.getBusinessArea(id)
      return area ? area.description : ''
    },
    setSearch () : void {
      if (screen.width >= 768) {
        this.searchTextPlaceholder = 'Search job title or keyword...'
      } else {
        this.searchTextPlaceholder = 'Search keyword...'
      }
      this.searchText = this.$store.state.enteredSearchText
    },
    matchesSearchText (j : Job) : boolean {
      let text = this.searchText.toLowerCase()

      return this.getLocation(j.officeCode).toLowerCase().includes(text) ||
             this.getBusinessAreaDescription(j.businessAreaId).toLowerCase().includes(text) ||
             this.getDepartmentDescription(j.departmentId).toLowerCase().includes(text) ||
             j.shift.toLowerCase().includes(text) ||
             j.title.toLowerCase().includes(text) ||
             j.shortDescription.toLowerCase().includes(text)
    },
    getShareLink (jobId: string) : string {
      return window.location.origin + '/#/jobs/' + jobId
    },
    shareClicked (jobId: string) : void {
      setTimeout(() => {
        this.$root.$emit('bv::hide::popover', `share-link-${jobId}-popover`)
      }, 5000)
    },
    copyShareLink (jobId: string) : void {
      let link = this.getShareLink(jobId)
      navigator.clipboard.writeText(link)

      setTimeout(() => {
        this.$root.$emit('bv::hide::tooltip', `copy-share-link-${jobId}-tooltip`)
        this.$root.$emit('bv::hide::popover', `share-link-${jobId}-popover`)
      }, 2000)
    },
  },
})
