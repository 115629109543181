


















import Vue from 'vue'

export default Vue.extend({
  name: 'Header',
  methods: {
    goToLink () {
      window.location.href ='https://web.labattfood.com'
    },
  },
})
