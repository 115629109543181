







































import Vue from 'vue'

export default Vue.extend({
  name: 'Footer',
  methods: {
    openLink (link: string) {
      window.open(link, '_blank')
    },
  },
})
