

































































import Vue from 'vue'
import axios from 'axios'
import JobSearchList from '@/components/JobSearchList.vue'

export default Vue.extend({
  name: 'BusinessAreas',
  components: {
    JobSearchList,
  },
  data () {
    return {
      link: '',
    }
  },
  async created () {
    await this.getJobSearchLink()
  },
  methods: {
    async getJobSearchLink () {
      let r = await axios.get('/api/Resources/job-search-link')
      if (r.status == 200) {
        this.link = r.data
      }
    },
    goToJobSearch (area : string) {
      if (this.link) {
        let url = this.link
        if (area) {
          url += `?businessArea=${area}`
        }
        window.open(url)
      }
    },
  },
})
