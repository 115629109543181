





















import Vue from 'vue'
import { Office, BusinessArea, Department } from '@/types'

export default Vue.extend({
  name: 'JobSearchPills',
  computed: {
    selectedLocations () : Office[] {
      let locations = this.lodash.clone(this.$store.state.selectedLocations.map((l : string) : Office => this.getLocation(l)))
      this.lodash.remove(locations, (l : Office) : boolean => { return l.code === '3' || l.code === '4' || l.code === 'M' })
      return locations
    },
    selectedBusinessAreas () : BusinessArea[] {
      return this.$store.state.selectedBusinessAreas.map((a : string) : BusinessArea => this.getBusinessArea(a))
    },
    selectedDepartments () : Department[] {
      return this.$store.state.selectedDepartments.map((d : string) : Department => this.getDepartment(d))
    },
    selectedShifts () : string [] {
      return this.$store.state.selectedShifts
    }
  },
  methods: {
    getLocation (code: string) : Office {
      return this.$store.getters.getLocation(code)
    },
    getBusinessArea (id : string) : BusinessArea {
      return this.$store.getters.getBusinessArea(id)
    },
    getDepartment (id: string) : Department {
      return this.$store.getters.getDepartment(id)
    },
    unselectLocation (code : string) : void {
      this.$store.dispatch('unselectLocation', code)
    },
    unselectBusinessArea (id : string) : void {
      this.$store.dispatch('unselectBusinessArea', id)
    },
    unselectDepartment (id : string) : void {
      this.$store.dispatch('unselectDepartment', id)
    },
    unselectShift (shift : string) : void {
      this.$store.dispatch('unselectShift', shift)
    }
  }
})
