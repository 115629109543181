import { render, staticRenderFns } from "./JobSearch.vue?vue&type=template&id=448b478e&scoped=true&"
import script from "./JobSearch.vue?vue&type=script&lang=ts&"
export * from "./JobSearch.vue?vue&type=script&lang=ts&"
import style0 from "./JobSearch.vue?vue&type=style&index=0&id=448b478e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "448b478e",
  null
  
)

export default component.exports