

















import Vue from 'vue'
import JobSearchFilters from '@/components/JobSearchFilters.vue'
import JobSearchList from '@/components/JobSearchList.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios'

export default Vue.extend({
  name: 'JobSearch',
  components: {
    JobSearchFilters,
    JobSearchList,
    Header,
    Footer,
  },
  async created () {
    await this.postPageLog()
  },
  methods: {
    async postPageLog () {
      let url = ''
      let referrer = document.referrer
      let origins = window.location.ancestorOrigins
      let parent = window.parent.origin
      let current = window.origin
      // Leave console logs to test in prod
      console.log('Job Search')
      console.log(referrer)
      console.log(origins)
      console.log(parent)
      console.log(current)
      if (referrer) {
        url = referrer
      } else if (origins && origins.length) {        
        url = origins[origins.length - 1]
      } else if (parent && parent !== current) {        
        url = parent
      }
      let data = {
        webHistory: url,
      }
      await axios.post('/api/logs/page-log', data)
    },
  },
})
