






































import Vue from 'vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { VueAgile } from 'vue-agile'

export default Vue.extend({
  name: 'Videos',
  components: {
    agile: VueAgile,
  },
  data() {
    return {
      selectedVideo: '',
      options: {
        dots: false,
        slidesToScroll: 1,
        slidesToShow: 3,
        navButtons: true,
        mobileFirst: true,
        responsive: [
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 3,
            navButtons: true,
          }
        },
        {
          breakpoint: 720,
          settings: {
            slidesToShow: 4,
            navButtons: true,
          }
        },
        {
          breakpoint: 950,
          settings: {
            slidesToShow: 5,
            navButtons: true,
          }
        },
        {
          breakpoint: 1150,
          settings: {
            slidesToShow: 6,
            navButtons: true,
          }
        },
        {
          breakpoint: 1250,
          settings: {
            slidesToShow: 7,
            navButtons: false,
            slidesToScroll: 0,
          }
        }]
      },
      slides: [
        require('../assets/Thumbnails_Introduction.jpg'),
        require('../assets/Thumbnails_Buying.jpg'),
        require('../assets/Thumbnails_Manufacturing.jpg'),
        require('../assets/Thumbnails_Warehouse.jpg'),
        require('../assets/Thumbnails_Deliver.jpg'),
        require('../assets/Thumbnails_Serve.jpg'),
        require('../assets/Thumbnails_Community.jpg'),
      ],
      videos: [
        'https://player.vimeo.com/video/560913781?autoplay=1',
        'https://player.vimeo.com/video/560913639?autoplay=1',
        'https://player.vimeo.com/video/560913557?autoplay=1',
        'https://player.vimeo.com/video/560913452?autoplay=1',
        'https://player.vimeo.com/video/560913406?autoplay=1',
        'https://player.vimeo.com/video/560913303?autoplay=1',
        'https://player.vimeo.com/video/560913196?autoplay=1',
      ],
    }
  },
  created () {
    this.selectedVideo = 'https://player.vimeo.com/video/560913781'
  },
  methods: {
    goToVideo (index: number) {
      this.selectedVideo = this.videos[index]
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$refs.thumbnails.goTo(index)
      parent.postMessage({'task': 'scroll_to_videos'}, 'https://web.labattfood.com')
    },
  },
})
