




































































import Vue from 'vue'
import { BusinessArea, Office, Department, Job } from '@/types'

export default Vue.extend({
  name: 'JobSearchFilters',
  data () {
    return {
      showFilters: false,
      toggleFiltersBtn: '',
    }
  },
  async created () {
    await this.setParams()
    this.setFilters()
  },
  computed: {
    selectedLocations : {
      get() {
        return this.$store.state.selectedLocations
      },
      set(newValue : string[]) {
        const relatedCodes = ['3', '4', 'M']
        if (newValue.includes('S')) {
          relatedCodes.forEach((code : string) : void => {
            if (!newValue.includes(code)) {
              newValue.push(code)
            }
          })
        } else {
          relatedCodes.forEach((code : string) : void => {
            let i = newValue.indexOf(code)
            if (i > -1) {
              newValue.splice(i, 1)
            }
          })
        }
        this.$store.dispatch('setSelectedLocations', newValue)
      }
    },
    selectedBusinessAreas : {
      get() {
        return this.$store.state.selectedBusinessAreas
      },
      set(newValue : string[]) {
        this.$store.dispatch('setSelectedBusinessAreas', newValue)
      }
    },
    selectedDepartments : {
      get() {
        return this.$store.state.selectedDepartments
      },
      set(newValue : string[]) {
        this.$store.dispatch('setSelectedDepartments', newValue)
      }
    },
    selectedShifts: {
      get() {
        return this.$store.state.selectedShifts
      },
      set(newValue : string[]) {
        this.$store.dispatch('setSelectedShifts', newValue)
      }
    },
    jobs () : Job[] {
      return this.$store.state.jobs
    },
    locations () : Office[] {
      let offices = [] as Office[]
      this.$store.state.locations.forEach((o : Office) => {
        if (o && o.code !== '3' && o.code !== '4' && o.code !== 'M') {
          offices.push(o)
        }
      })
      return this.lodash.orderBy(offices, 'city')
    },
    businessAreas () : BusinessArea[] {
      return this.$store.state.businessAreas
    },
    departments () : Department[] {
      return this.$store.state.departments
    },
    shifts () {
      return ['Day', 'Night']
    },
    showClearFiltersBtn () {
      return this.$store.state.selectedLocations.length > 0 ||
             this.$store.state.selectedBusinessAreas.length > 0 ||
             this.$store.state.selectedDepartments.length > 0 ||
             this.$store.state.selectedShifts.length > 0
    },
  },
  methods: {
    async setParams () {
      await this.$store.dispatch('setJobs')

      if (this.$route.query.businessArea) {
        await this.$store.dispatch('setBusinessAreas')

        let area = this.businessAreas.find(a => a.description === this.$route.query.businessArea)
        if (area) {
          this.selectedBusinessAreas = [area.id]
        }
      } else if (this.$route.query.businessAreas) {
        await this.$store.dispatch('setBusinessAreas')

        let businessAreaIds = []
        let businessAreas = JSON.parse('[' + this.$route.query.businessAreas + ']')[0]

        for (let i = 0; i < businessAreas.length; i++) {
          let area = this.businessAreas.find(a => a.description === businessAreas[i])
          if (area) {
            businessAreaIds.push(area.id)
          }
        }
        if (businessAreaIds.length) {
          this.selectedBusinessAreas = businessAreaIds
        }
      } else {
        this.selectedBusinessAreas = this.$store.state.selectedBusinessAreas
      }

      if (this.$route.query.location) {
        await this.$store.dispatch('setLocations')

        let loc = this.locations.find(o => o.code === this.$route.query.location)
        if (loc) {
          this.selectedLocations = [loc.code]
        }
      } else if (this.$route.query.locations) {
        await this.$store.dispatch('setLocations')

        let locationIds = []
        let locations = JSON.parse('[' + this.$route.query.locations + ']')[0]

        for (let i = 0; i < locations.length; i++) {
          let loc = this.locations.find(o => o.code === locations[i])
          if (loc) {
            locationIds.push(loc.code)
          }
        }
        if (locationIds.length) {
          this.selectedLocations = locationIds
        }
      } else {
        this.selectedLocations = this.$store.state.selectedLocations
      }

      if (this.$route.query.department) {
        await this.$store.dispatch('setDepartments')

        let dept = this.departments.find(d => d.description === this.$route.query.department)
        if (dept) {
          this.selectedDepartments = [dept.id]
        }
      } else if (this.$route.query.departments) {
        await this.$store.dispatch('setDepartments')

        let departmentIds = []
        let departments = JSON.parse('[' + this.$route.query.departments + ']')[0]

        for (let i = 0; i < departments.length; i++) {
          let dept = this.departments.find(d => d.description === departments[i])
          if (dept) {
            departmentIds.push(dept.id)
          }
        }
        if (departmentIds.length) {
          this.selectedDepartments = departmentIds
        }
      } else {
        this.selectedDepartments = this.$store.state.selectedDepartments
      }

      if (this.$route.query.shift) {
        await this.$store.dispatch('setShifts')
        this.selectedShifts = [this.$route.query.shift.toString()]
      } else if (this.$route.query.shifts) {
        await this.$store.dispatch('setShifts')

        let shifts = JSON.parse('[' + this.$route.query.shifts + ']')[0]
        if (shifts.length) {
          this.selectedShifts = shifts
        }
      } else {
        this.selectedShifts = this.$store.state.selectedShifts
      }
    },
    clearFilters () {
      this.selectedLocations = []
      this.selectedBusinessAreas = []
      this.selectedDepartments = []
      this.selectedShifts = []
    },
    toggleFilters () {
      this.$store.dispatch('setShowJobList', this.showFilters)
      this.showFilters = !this.showFilters
      if (this.showFilters) {
        this.toggleFiltersBtn = 'Hide Filters'
      } else {
        this.toggleFiltersBtn = 'Show Filters'
      }
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    setFilters () {
      if (screen.width >= 768) {
        this.showFilters = true
      } else {
        this.showFilters = false
        this.toggleFiltersBtn = 'Show Filters'
      }
    }
  },
})
