import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueAgile from 'vue-agile'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueLodash from 'vue-lodash'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import lodash from 'lodash'
import DateMixins from './mixins/DateMixins'
import HelperMixins from './mixins/HelperMixins'
// Transpiler for older browsers
import 'core-js/stable'
import 'regenerator-runtime/runtime'

library.add(faChevronLeft)
library.add(faChevronRight)
library.add(faTimes)

Vue.mixin(DateMixins)
Vue.mixin(HelperMixins)

Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.use(BootstrapVue)
Vue.use(VueAgile)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueLodash, { lodash: lodash })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
