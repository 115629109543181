




import Vue from 'vue'
import axios from 'axios'
import { Job } from '@/types'

export default Vue.extend({
  name: 'SpecificJobSearch',
  created () {
    this.reload()
  },
  methods: {
    async reload () {
      let r = await axios.get('/api/jobs')
      if (r.status == 200) {
        let jobs = r.data
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let job = jobs.find((j: Job) => j.title.toLowerCase().includes(this.$route.query.position.toLowerCase()) &&
                                        j.officeCode === this.$route.query.location)

        let jobId = job ? job.id : 'not-found'
        this.$router.push({name: 'JobDetails', params: {id: jobId}})
      }
    },
  },
})
