import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import BusinessAreas from '../views/BusinessAreas.vue'
import JobSearch from '../views/JobSearch.vue'
import JobDetails from '../views/JobDetails.vue'
import Videos from '../views/Videos.vue'
import SpecificJobSearch from '../views/SpecificJobSearch.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'JobSearch',
    component: JobSearch,
  },
  {
    path: '/jobs',
    name: 'SpecificJobSearch',
    component: SpecificJobSearch,
  },
  {
    path: '/jobs/:id',
    name: 'JobDetails',
    component: JobDetails,
  },
  {
    path: '/business-areas',
    name: 'BusinessAreas',
    component: BusinessAreas,
  },
  {
    path: '/videos',
    name: 'Videos',
    component: Videos,
  },
]

const router = new VueRouter({
  routes
})

export default router