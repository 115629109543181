



















































import Vue from 'vue'
import axios from 'axios'
import { Job } from '@/types'

export default Vue.extend({
  name: 'RelatedJobs',
  props: ['job'],
  data () {
    return {
      loading: false,
      jobs: [] as Job[],
      options: {
        dots: false,
        responsive: [
          {
            breakpoint: 300,
            settings: {
              slidesToShow: 1,
              navButtons: false,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 3,
              navButtons: false,
            },
          },
        ],
      },
    }
  },
  async created () {
    this.loading = true
    await this.getJobs()
    this.loading = false
  },
  watch: {
    relatedJobs () {
      if (this.relatedJobs.length > 3) {
        this.options.responsive[1].settings.slidesToShow = 3
        this.options.responsive[1].settings.navButtons = true
      } else {
        this.options.responsive[1].settings.slidesToShow = this.relatedJobs.length
        this.options.responsive[1].settings.navButtons = false
      }
    },
  },
  computed: {
    relatedJobs () : Job[] {
      let foundJobs = this.jobs.filter(j => j.officeCode === this.job.officeCode &&
        j.businessAreaId === this.job.businessAreaId &&
        j.departmentId === this.job.departmentId &&
        j.id !== this.job.id
      )
      let sortList = ['desc', 'desc', 'desc', 'asc']

      if (this.job && this.job.shift === 'Day') {
        sortList = ['asc', 'desc', 'desc', 'asc']
      }
      return this.lodash.orderBy(foundJobs, ['shift', 'priority', 'postingDate', 'title'], sortList)
    },
    showRelatedJobs () : boolean {
      return !this.loading && this.relatedJobs.length > 0
    },
  },
  methods: {
    async getJobs () {
      let r = await axios.get('/api/jobs')
      if (r.status == 200) {
        this.jobs = r.data
      }
    },
    apply (jobId: string) {
      this.$router.push({name: 'JobDetails', params: {id: jobId}})
    },
    getLocation (code: string) {
      let loc = this.$store.getters.getLocation(code)
      return loc ? `${loc.city}, ${loc.state}` : ''
    },
    getDepartmentDescription (id: string) {
      let dep = this.$store.getters.getDepartment(id)
      return dep ? dep.description : ''
    },
    getBusinessAreaDescription (id: string) {
      let area = this.$store.getters.getBusinessArea(id)
      return area ? area.description : ''
    },
  },
})
