import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { Office, BusinessArea, Department, Job } from '@/types'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    jobs: [] as Job[],
    locations: [] as Office[],
    businessAreas: [] as BusinessArea[],
    departments: [] as Department[],
    selectedLocations: [] as string[],
    selectedBusinessAreas: [] as string[],
    selectedDepartments: [] as string[],
    selectedShifts: [] as string[],
    showJobList: true,
    enteredSearchText: '',
  },
  mutations: {
    setJobs(state, jobs: Job[]) {
      state.jobs = jobs
    },
    setLocations(state, locations: Office[]) {
      state.locations = locations
    },
    setBusinessAreas(state, businessAreas: BusinessArea[]) {
      state.businessAreas = businessAreas
    },
    setDepartments(state, departments: Department[]) {
      state.departments = departments
    },
    setSelectedLocations(state, locations: string[]) {
      state.selectedLocations = locations
    },
    setSelectedBusinessAreas(state, businessAreas: string[]) {
      state.selectedBusinessAreas = businessAreas
    },
    setSelectedDepartments(state, departments: string[]) {
      state.selectedDepartments = departments
    },
    setSelectedShifts(state, shifts: string[]) {
      state.selectedShifts = shifts
    },
    setShowJobList(state, show) {
      state.showJobList = show
    },
    setEnteredSearchText(state, searchText) {
      state.enteredSearchText = searchText
    },
  },
  actions: {
    async setJobs(context) {
      try {
        const r = await axios.get('/api/jobs')
        if (r.status == 200) {
          context.commit('setJobs', r.data)
        }
      } catch (error) {
        context.dispatch('logError', error)
      }
    },
    async setLocations(context) {
      try {
        const r = await axios.get('/api/Filters/locations')
        if (r.status == 200) {
          context.commit('setLocations', r.data)
        }
      } catch (error) {
        context.dispatch('logError', error)
      }
    },
    async setBusinessAreas(context) {
      try {
        const r = await axios.get('/api/Filters/business-areas')
        if (r.status == 200) {
          context.commit('setBusinessAreas', r.data)
        }
      } catch (error) {
        context.dispatch('logError', error)
      }
    },
    async setDepartments(context) {
      try {
        const r = await axios.get('/api/Filters/departments')
        if (r.status == 200) {
          context.commit('setDepartments', r.data)
        }
      } catch (error) {
        context.dispatch('logError', error)
      }
    },
    setSelectedLocations(context, locations) {
      context.commit('setSelectedLocations', locations)
    },
    unselectLocation(context, code) {
      let locations = context.state.selectedLocations.filter(l => l !== code)
      if (code === 'S') {
        locations = locations.filter(l => !['3','4','M'].includes(l))
      }
      context.commit('setSelectedLocations', locations)
    },
    setSelectedBusinessAreas(context, businessAreas) {
      context.commit('setSelectedBusinessAreas', businessAreas)
    },
    unselectBusinessArea(context, businessAreaId) {
      const areas = context.state.selectedBusinessAreas.filter(a => a !== businessAreaId)
      context.commit('setSelectedBusinessAreas', areas)
    },
    setSelectedDepartments(context, departments) {
      context.commit('setSelectedDepartments', departments)
    },
    unselectDepartment(context, departmentId) {
      const departments = context.state.selectedDepartments.filter(d => d !== departmentId)
      context.commit('setSelectedDepartments', departments)
    },
    setSelectedShifts(context, shifts) {
      context.commit('setSelectedShifts', shifts)
    },
    unselectShift(context, shift) {
      const shifts = context.state.selectedShifts.filter(s => s !== shift)
      context.commit('setSelectedShifts', shifts)
    },
    setShowJobList(context, show) {
      context.commit('setShowJobList', show)
    },
    setEnteredSearchText(context, searchText) {
      context.commit('setEnteredSearchText', searchText)
    },
    logError(context, error) {
      if (error.response) {
        console.log('Status:', error.response.status)
        console.log('Data:', error.response.data)
        console.log('Headers:', error.response.headers)
      } else if (error.request) {
        console.log('Request:', error.request)
      } else {
        console.log('Error:', error.message)
      }
      console.log('Config:', error.config)
    }
  },
  getters: {
    getLocation: (state) => (code: string) => {
      return state.locations.find(l => l.code === code)
    },
    getBusinessArea: (state) => (id: string) => {
      return state.businessAreas.find(a => a.id === id)
    },
    getDepartment: (state) => (id: string) => {
      return state.departments.find(d => d.id === id)
    },
  },
  modules: {
  }
})
