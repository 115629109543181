






import Vue from 'vue'

export default Vue.extend({
  name: 'App',
  async created () {
    await this.$store.dispatch('setLocations')
    await this.$store.dispatch('setBusinessAreas')
    await this.$store.dispatch('setDepartments')
  },
})
